// Here you can add other styles
.notification{
    position: fixed;
    top: 45%;
    left: 40%;

    height: 100px;
    width: 500px;

    background-color: #ffffff;
    z-index: 20;

    border: 1px solid black;

    display: flex;
    align-items: center;
    justify-content: center;
}